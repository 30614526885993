<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Successen', path: '/blog' },
      { name: 'Bewerk Succes', path: `/blog/edit/${$route.params.id}` }
    ]" />

    <h1 class="text-3xl font-bold mb-8">Bewerk Succes</h1>
    
    <div v-if="loading" class="text-center py-8">
      <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
      <p class="mt-2 text-gray-600">Laden...</p>
    </div>

    <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
      {{ error }}
    </div>

    <form v-else @submit.prevent="handleSubmit" class="space-y-6">
      <div>
        <label for="title" class="block text-sm font-medium text-gray-700">Titel</label>
        <input
          type="text"
          id="title"
          v-model="title"
          required
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
      </div>

      <div>
        <label class="block text-sm font-medium text-gray-700 mb-2">Content</label>
        <BlogEditor v-model="content" />
      </div>

      <div>
        <label for="coverImage" class="block text-sm font-medium text-gray-700">Cover Afbeelding</label>
        <input
          type="file"
          id="coverImage"
          accept="image/*"
          @change="handleCoverImageUpload"
          class="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-purple-50 file:text-purple-700 hover:file:bg-purple-100"
        />
        <img 
          v-if="coverImagePreview || currentCoverImage" 
          :src="coverImagePreview || currentCoverImage" 
          alt="Cover preview" 
          class="mt-2 max-h-48 object-cover rounded-lg"
        >
      </div>

      <div>
        <label for="youtubeUrl" class="block text-sm font-medium text-gray-700">YouTube Video URL</label>
        <input
          type="text"
          id="youtubeUrl"
          v-model="youtubeUrl"
          placeholder="Plak YouTube URL hier"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
        />
      </div>

      <div>
        <label class="block text-sm font-medium text-gray-700">Top Post Ranking</label>
        <div class="mt-2">
          <select 
            v-model="topPost" 
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            <option :value="0">Geen top post</option>
            <option :value="1">Top 1</option>
            <option :value="2">Top 2</option>
            <option :value="3">Top 3</option>
          </select>
        </div>
      </div>

      <div>
        <label class="block text-sm font-medium text-gray-700 mb-2">Categorieën</label>
        <div class="mt-2 space-y-2">
          <!-- Predefined categories -->
          <div v-for="category in availableCategories" :key="category">
            <label class="inline-flex items-center">
              <input 
                type="checkbox" 
                v-model="selectedCategories" 
                :value="category"
                class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
              >
              <span class="ml-2">{{ category }}</span>
            </label>
          </div>

          <!-- Custom category input -->
          <div class="mt-4">
            <label class="inline-flex items-center">
              <input 
                type="checkbox" 
                v-model="showCustomCategory"
                class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
              >
              <span class="ml-2">Eigen categorie toevoegen</span>
            </label>
            
            <div v-if="showCustomCategory" class="mt-2">
              <div class="flex gap-2">
                <input
                  type="text"
                  v-model="customCategory"
                  placeholder="Voer categorie naam in"
                  class="flex-1 rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                />
                <button
                  type="button"
                  @click="addCustomCategory"
                  class="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
                >
                  Toevoegen
                </button>
              </div>
            </div>
          </div>

          <!-- Display custom categories -->
          <div v-if="customCategories.length > 0" class="mt-4">
            <div v-for="category in customCategories" :key="category" class="flex items-center justify-between py-1">
              <label class="inline-flex items-center">
                <input 
                  type="checkbox" 
                  v-model="selectedCategories" 
                  :value="category"
                  class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                >
                <span class="ml-2">{{ category }}</span>
              </label>
              <button
                type="button"
                @click="removeCustomCategory(category)"
                class="text-red-600 hover:text-red-800"
              >
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label class="block text-sm font-medium text-gray-700">Status</label>
        <div class="mt-2">
          <label class="inline-flex items-center">
            <input type="radio" v-model="isActive" :value="true" class="form-radio text-purple-600">
            <span class="ml-2">Actief</span>
          </label>
          <label class="inline-flex items-center ml-6">
            <input type="radio" v-model="isActive" :value="false" class="form-radio text-purple-600">
            <span class="ml-2">Inactief</span>
          </label>
        </div>
      </div>

      <div class="flex justify-end space-x-4">
        <button
          type="button"
          @click="$router.push(`/blog/${$route.params.id}`)"
          class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
          Annuleren
        </button>
        <button
          type="submit"
          :disabled="isSubmitting"
          class="inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
          {{ isSubmitting ? 'Opslaan...' : 'Opslaan' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { db, storage } from '../firebase/config'
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import BlogEditor from '../components/BlogEditor.vue'
import Breadcrumb from '../components/Breadcrumb.vue'
import { useAuthStore } from '../stores/auth'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const title = ref('')
const content = ref('')
const coverImage = ref(null)
const coverImagePreview = ref(null)
const currentCoverImage = ref(null)
const youtubeUrl = ref('')
const loading = ref(true)
const error = ref(null)
const isSubmitting = ref(false)
const selectedCategories = ref([])
const isActive = ref(true)
const showCustomCategory = ref(false)
const customCategory = ref('')
const customCategories = ref([])
const topPost = ref(0)

const availableCategories = [
  'Techniek',
  'ICT',
  'Zorg',
  'Economie',
  'Sport',
  'Onderwijs',
  'Stage',
  'Project',
  'Event'
]

onMounted(async () => {
  if (!authStore.isTeacher) {
    router.push('/blog')
    return
  }

  try {
    const docRef = doc(db, 'blogPosts', route.params.id)
    const docSnap = await getDoc(docRef)
    
    if (docSnap.exists()) {
      const post = docSnap.data()
      title.value = post.title
      content.value = post.content
      currentCoverImage.value = post.coverImage
      youtubeUrl.value = post.youtubeUrl || ''
      isActive.value = post.isActive !== false
      selectedCategories.value = post.categories || []
      topPost.value = post.topPost || 0
      
      // Separate custom categories from predefined ones
      customCategories.value = selectedCategories.value.filter(
        cat => !availableCategories.includes(cat)
      )
    } else {
      error.value = 'Post niet gevonden'
    }
  } catch (err) {
    console.error('Error fetching blog post:', err)
    error.value = 'Fout bij het laden van het bericht. Probeer het later opnieuw.'
  } finally {
    loading.value = false
  }
})

const handleCoverImageUpload = (event) => {
  const file = event.target.files[0]
  if (file) {
    coverImage.value = file
    coverImagePreview.value = URL.createObjectURL(file)
  }
}

const addCustomCategory = () => {
  if (customCategory.value.trim()) {
    const newCategory = customCategory.value.trim()
    if (!customCategories.value.includes(newCategory)) {
      customCategories.value.push(newCategory)
      selectedCategories.value.push(newCategory)
      customCategory.value = ''
    }
  }
}

const removeCustomCategory = (category) => {
  customCategories.value = customCategories.value.filter(c => c !== category)
  selectedCategories.value = selectedCategories.value.filter(c => c !== category)
}

const handleSubmit = async () => {
  if (!authStore.isTeacher) {
    alert('Alleen docenten kunnen berichten bewerken')
    return
  }

  try {
    isSubmitting.value = true
    let coverImageUrl = currentCoverImage.value

    if (coverImage.value) {
      const fileRef = storageRef(storage, `blog-covers/${Date.now()}_${coverImage.value.name}`)
      await uploadBytes(fileRef, coverImage.value)
      coverImageUrl = await getDownloadURL(fileRef)
    }

    const blogPost = {
      title: title.value,
      content: content.value,
      coverImage: coverImageUrl,
      youtubeUrl: youtubeUrl.value,
      categories: selectedCategories.value,
      isActive: isActive.value,
      topPost: topPost.value,
      updatedAt: serverTimestamp()
    }

    await updateDoc(doc(db, 'blogPosts', route.params.id), blogPost)
    router.push(`/blog/${route.params.id}`)
  } catch (error) {
    console.error('Error updating blog post:', error)
    alert('Fout bij het bijwerken van het bericht. Probeer het opnieuw.')
  } finally {
    isSubmitting.value = false
  }
}
</script>
```